import {
  ADD_LOG_ERROR,
  ADD_LOG_REQUEST,
  ADD_LOG_RESPONSE,
  DISMISS_CASE_FLAG_ERROR,
  GENERATE_DOCUMENT_FAILED,
  GENERATE_DOCUMENT_RESPONSE,
  INIT_CASE,
  REMOVE_DOCUMENTS_RESPONSE,
  RETRIEVE_CASE_ID_REQUEST,
  RETRIEVE_CASE_ID_RESPONSE,
  RETRIEVE_HEARING_DATES_REQUEST,
  RETRIEVE_HEARING_DATES_RESPONSE,
  SELECT_ALL_DOCUMENT_FILE,
  SELECT_DOCUMENT_FILE,
  SET_STATE_CASE,
  UPDATE_FIELD_CASE_STATE, UPDATE_STATUS_REQUEST,
  UPDATE_VALUE_CASE,
  UPDATE_VALUE_CASE_PASSENGER,
  GET_LOGS_AUTOMATICS, GET_LOGS_AUTOMATICS_RESPONSE, GENERATE_DOCUMENT_REQUEST,
} from './case.action';

const { v4: uuidv4 } = require('uuid');

const initialCaseState = {
  case: null,
  loading: false,
  documentLoading: false,
  loadingComment: false,
  loadingAutomatics: false,
  errors: {
    generateDocs: [],
  },
  success: {
    generateDocs: [],
  },
  state: 'INFO',
  automaticsLogs: [],
};

export function removeSelectedElementInArray(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].selected) {
      arr.splice(i, 1);
      i--;
    }
  }
  return arr;
}

function selectDoc(val, id) {
  if (val._id === id) {
    return {
      ...val,
      selected: !val.selected,
    };
  }
  return {
    ...val,
  };
}

export const caseReducer = (state = initialCaseState, action) => {
  switch (action.type) {
    case GET_LOGS_AUTOMATICS: {
      return {
        ...state,
        loadingAutomatics: true,
      };
    }

    case GET_LOGS_AUTOMATICS_RESPONSE: {
      return {
        ...state,
        loadingAutomatics: false,
        automaticsLogs: action.payload.response,
      };
    }

    case SET_STATE_CASE:
      return {
        ...state,
        state: action.payload,
      };

    case INIT_CASE:
      return {
        ...state,
        case: null,
      };

    case UPDATE_STATUS_REQUEST: {
      return {
        ...state,
        case: {
          ...state.case,
          status: action.payload.status,
        },
      };
    }

    case RETRIEVE_CASE_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RETRIEVE_CASE_ID_RESPONSE:
      return {
        ...state,
        loading: false,
        case: {
          ...action.payload.data,
        },
      };

    case ADD_LOG_REQUEST:
      return {
        ...state,
        loadingComment: true,
      };

    case ADD_LOG_RESPONSE:
      return {
        ...state,
        loadingComment: false,
      };

    case ADD_LOG_ERROR:
      return {
        ...state,
        loadingComment: false,
      };

    case UPDATE_FIELD_CASE_STATE: {
      return {
        ...state,
      };
    }

    case UPDATE_VALUE_CASE:
      const { field, value } = action.payload;
      const freshCase = {
        ...state.case,
        [field]: value,
      };
      return {
        ...state,
        case: freshCase,
      };

    case UPDATE_VALUE_CASE_PASSENGER: {
      const { field, value, _id } = action.payload;
      const passenger = state.case.passengers.find((elem) => elem._id === _id);
      if (field === 'hearingDate') {
        passenger.hearingDate.unshift(value);
        const updatedPassenger = {
          ...passenger,
        };
        return {
          ...state,
          case: {
            ...state.case,
            passengers: state.case.passengers.map((el) => (el._id === _id ? updatedPassenger : el)),
          },
        };
      }
      const updatedPassenger = {
        ...passenger,
        [field]: value,
      };
      return {
        ...state,
        case: {
          ...state.case,
          passengers: state.case.passengers.map((el) => (el._id === _id ? updatedPassenger : el)),
        },
      };
    }

    case SELECT_DOCUMENT_FILE:
      const { _id } = action.payload;

      return {
        ...state,
        case: {
          ...state.case,
          documents: state.case.documents.map((e) => selectDoc(e, _id)),
        },
      };

    case SELECT_ALL_DOCUMENT_FILE:
      return {
        ...state,
        case: {
          ...state.case,
          documents: state.case.documents.map((e) => ({
            ...e,
            selected: !e.selected,
          })),
        },
      };

    case GENERATE_DOCUMENT_RESPONSE: {
      if (!action.payload.success) {
        const errors = {
          ...state.errors,
        };

        const id = uuidv4();
        errors.generateDocs.unshift({
          created: Date.now(),
          id,
          key: id,
          title: 'Ooopsy, le doc ne s\'est pas généré !',
          appearance: 'error',
        });

        return {
          ...state,
          documentLoading: false,
          errors,
        };
      }
      const errors = {
        ...state.errors,
      };

      const id = uuidv4();
      errors.generateDocs.unshift({
        created: Date.now(),
        id,
        key: id,
        title: 'Document généré avec succès!',
        appearance: 'success',
      });
      return {
        ...state,
        documentLoading: false,
        errors,
      };
    }

    case DISMISS_CASE_FLAG_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          generateDocs: state.errors.generateDocs.slice(1),
        },
      };

    case GENERATE_DOCUMENT_FAILED: {
      const errors = {
        ...state.errors,
      };

      const id = uuidv4();
      errors.generateDocs.unshift({
        created: Date.now(),
        id,
        key: id,
        title: action.payload?.message || "Ooopsy, le doc ne s'est pas généré !",
        appearance: 'error',
      });

      return {
        ...state,
        documentLoading: false,
        errors,
      };
    }
    case REMOVE_DOCUMENTS_RESPONSE:
      const id = uuidv4();
      const documents = removeSelectedElementInArray(state.case.documents);
      const errors = {
        ...state.errors,
      };

      errors.generateDocs.unshift({
        created: Date.now(),
        id,
        key: id,
        title: 'Documents supprimés avec succès!',
        appearance: 'success',
      });

      return {
        ...state,
        errors,
        case: {
          ...state.case,
          documents,
        },
      };

    case RETRIEVE_HEARING_DATES_REQUEST:
      return {
        ...state,
      };
    case GENERATE_DOCUMENT_REQUEST:
      return {
        ...state,
        documentLoading: true,
      };

    case RETRIEVE_HEARING_DATES_RESPONSE:
      const { data } = action.payload;
      if (state.case) {
        const passengers = state.case.passengers.map((pass) => {
          if (pass._id === action.payload.id) {
            return {
              ...pass,
              hearings: data,
            };
          }
          return {
            ...pass,
          };
        });

        return {
          ...state,
          case: {
            ...state.case,
            passengers,
          },
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
